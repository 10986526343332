.icon {
  svg {
    color: inherit;
    fill: currentColor;
    stroke: currentColor;
    width: inherit;
    height: inherit;
  }

  &--12 {
    width: 12px;
    height: 12px;
  }

  &--13 {
    width: 13px;
    height: 13px;
  }

  &--small {
    width: 16px;
    height: 16px;
  }

  &--18 {
    width: 18px;
    height: 18px;
  }

  &--20 {
    width: 20px;
    height: 20px;
  }

  &--23 {
    width: 23px;
    height: 23px;
  }

  &--24 {
    width: 24px;
    height: 24px;
  }

  &--30 {
    width: 30px;
    height: 30px;
  }

  &--42 {
    width: 42px;
    height: 42px;
  }

  &__wrapper {
    &--round {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d5e1f9;

      &--stat {
        background-color: $brand-color;
      }

      &--alternateStat {
        background-color: $emerald;
      }

      &--blendStat {
        background: linear-gradient(90deg, $brand-color, $emerald );

      }
    }

    &--30 {
      width: 30px;
      height: 30px;
    }

    &--alert__standby {
      background-color: $emerald;
    }

    &--alert__unread {
      animation-name: color;
      animation-duration: 3s;
      animation-iteration-count: infinite;

      @keyframes color {
        0% {
          background-color: $bluey-grey-58;
        }
        50% {
          background-color: $sun-yellow
        }
        100% {
          background-color: $bluey-grey-58;
        }
      }
    }

    &--alert__catastrophic {
        animation-name: colour;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
  
        @keyframes colour {
          0% {
            background-color: $bluey-grey-58;
          }
          50% {
            background-color: $tomato;
          }
          100% {
            background-color: $bluey-grey-58;
          }
        }
      }

    &--60 {
      width: 60px;
      height: 60px;
    }
  }

  &--white {
    color: white;
  }

  &--notification__standby {
    color: #EAF0FC;
  }

  &--primary {
    color: $brand-color;
  }

  &--fill-primary {
    color: transparent;

    > svg {
      fill: $brand-color;
    }
  }

  &--success {
    color: transparent;

    > svg {
      fill: $success;
    }
  }

  &--fill-white {
    color: transparent;

    > svg {
      fill: $white;
    }
  }

  &--fill-black {
    color: transparent;

    > svg {
      fill: #000;
    }
  }

  &--fill-gray {
    color: transparent;

    > svg {
      fill: gray;
    }
  }

  &--fill-dodger-blue {
    color: transparent;

    > svg {
      fill: $brand-color;
    }

    &:hover {
      > svg {
        fill: $brand-color-hover;
      }
    }
  }

  &--excel {
    margin: auto;
    margin-top: 25px;
  }
}

.notify {
  background-color: rgb(255, 255, 255);
  //border-radius: 3px;
  padding: 0px;
  color: black;
}

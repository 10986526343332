@import "../variables";

.rights {
  &__title {
    margin: 0px 0px 20px 0px;
  }

  &__option {
    margin-top: 8px;
    font-size: 15px;
  }

  &__option-text {
    padding-top: 2px;
    font-size: 15px;
  }

  &__checkbox {
    //margin-top: 0px;
    max-width: 80px;
  }
}

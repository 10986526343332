@import '../variables';

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  padding: 40px 40px 37px;

  button {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__alone_right {
    justify-content: flex-end;
  }

  &__left {
    display: flex;
    justify-content: space-between;
  }

  &__right {
    display: flex;
    justify-content: space-between;
  }

  &--stats {
    align-items: flex-start;

    .toolbar__left {
      flex-direction: column;
      width: 60%;
    }

    .toolbar__right {
      flex-direction: column;
      width: 20%;
    }
  }
}

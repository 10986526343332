#jsShiftBody {
  background: #fff;
}

.accordion {
  width: 100%;

  &-card {
    width: 100%;
    background: #fff;
  }

  .accordion-card:nth-child(even) .card-header {
    background-color: rgba(76, 132, 255, 0.05);
  }

  .table__row--stripped {
    background: transparent;
  }

  .card-body {
    background: #fff;
    //padding-top: 18px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    .table__header {
      padding: 0 10px;
      background-color: rgba(76, 132, 255, 0.05);
    }

    .table__cell {
      border: 0;
    }

    .btn--primary {
      float: right;
      margin: 0 44px 30px 0;
    }

    .btn--success {
      float: right;
      margin: 0 44px 30px 0;
    }
  }
}

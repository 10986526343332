@import "../variables";
.container {
  width: 1366px;
  max-width: 100%;
  margin: 0 auto;

  &--nopadding {
    padding: 0 10px;
  }
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
}

@import "../variables";

.layout {
  padding: 9px 20px;

  &--wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &--dashboard {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: auto;
  }

  &--login {
    width: 100%;
    height: 100%;
    z-index: 2;
    //background: url(../assets/login-bg.png) no-repeat center center transparent;
    background-size: cover;
  }

  &--login-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    //background: url(../assets/login-bg.png) no-repeat center center transparent;
    background-size: cover;
  }

  &__column {
    padding: 40px;

    &--dash {
      min-height: 768px;

      &:first-child {
        max-width: 874px;
        width: 64%;
        // background-color: red;
        margin-right: 30px;
      }

      &:last-child {
        max-width: 422px;
        width: 31%;
        //   background-color: white;
      }
    }

    &--login {
      position: absolute;
      margin-top: -9px;
      width: 500px;
      height: 500px;
      max-width: 100%;
      margin-left: 128px;
      @media screen and (max-width: $sm) {
        margin-left: 0;
      }
    }

    &--login_round {
      position: absolute;
      z-index: -1;
      border-radius: 0 0 50% 50%;
      display: block;
      width: 1000px;
      height: 650px;
      margin-left: -290px;
      margin-top: -600px;
    }

    &--login_round_wrapper {
      position: absolute;
      z-index: -1;
      overflow: hidden;
      display: block;
      //background: blue;
      width: 500px;
      height: 200px;
      margin-left: 128px;
      margin-top: 480px;
    }

    &--full {
      width: 100%;
      max-width: 100%;
    }
  }
}

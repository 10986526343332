@import '../variables';

.card {
    display: inline-block;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;
    padding: 22px 20px;
    margin-right: 30px;
    flex: 1;

    &:last-child {
        margin-right: 0
    }

    &__row {
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
    }

    &__flow {
        margin-bottom: 25px;
        border-radius: 5px;
        background-color: $brand-color;
        color: white;
        justify-content: space-between;
    }

    &__title {
        color: $card-title;
        margin-bottom: 34px;
        line-height: 1;

        @extend .text--md;
        @extend .text--medium;

        &--danger {
            @extend .text--danger;
        }
    }

    &__content {

    }

}
.drawer {
    width: 229px;
    height: 100%;
    padding: 50px 0;
    background-color: $brand-color;
    z-index: 3;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__close {
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 15px;
    }

    &__item {
        margin: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;

        a {
            width: 100%;
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 17px 25px;
        }

        &:hover {
            background-color: $brand-color-hover;
        }

        span {
            font-family: "Source Sans Pro", sans-serif;

            font-size: 15px;
            font-weight: 400;
            line-height: 1.27;
            color: $white;
        }

        .icon {
            margin-right: 12px;
        }
    }
}

@import '../variables';

.form {
  &__input {
    max-width: 422px;
    margin: auto;

    &--barcode {
      position: relative;
      max-width: 100%;

      .icon {
        position: absolute;
        right: 8px;
        top: 8px;
        color: $bluey-grey;
      }
    }

    &--add {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;

      .icon {
        margin-left: 10px;
      }
    }

    &--generalSettings {
      max-width: 422px;
      margin: inherit;
    }
  }

  &__row {
    margin-bottom: 0.5rem;

    &--split {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &--split-nospace {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 20px;
    }

    &--regular {
      margin-bottom: 1.5rem;

      .form-label {
        margin-bottom: 0.5rem;
        display: block;
      }
    }
  }

  &__labelbold {
    margin-bottom: 15px;
    font-weight: bold;
  }

  &__labelmarginright {
    margin-right: 60px;
  }

  &__labeldetails {
    font-size: 12px;
    margin-bottom: 10px;
  }

  &__labeldetails a {
    color: $brand-color;
    text-decoration: none;
    transition: all 0.7s ease-in-out;
  }

  &__labeldetails a:hover {
    color: $gunmetal;
  }

  &__labelWithIcon {
    font-size: 14px;
    color: $brand-color;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon {
      margin-left: 5px;
    }
  }
}

@import "../variables";

.notfoundbody {
  margin: 0;
  overflow: hidden;
}

.notfoundbackground {
  width: 100vw;
  height: 100vh;
  background: $brand-color;
  display: flex;
	align-items: center;
	justify-content: center;
}

.notFoundElement {
  font-size: 120px;
}

$particleSize: 20vmin;
$animationDuration: 6s;
$amount: 40;
.notfoundbackground span {
  width: $particleSize;
  height: $particleSize;
  border-radius: $particleSize;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: $animationDuration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  $colors: ($pale-grey-two, $gunmetal, $cool-grey, $cornflower-blue, #0069B4);
  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      color: nth($colors, random(length($colors)));
      top: random(100) * 1%;
      left: random(100) * 1%;
      animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
      animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
      transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
      $blurRadius: (random() + 0.5) * $particleSize * 0.5;
      $x: if(random() > 0.5, -1, 1);
      //box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
    }
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

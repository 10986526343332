.d-none {
  display: none;
}

.inline-items-details {
  padding-top: 20px;
  background: #fff;

  .actions {
    background: #fff;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    button {
      float: right;
      margin-right: 20px;
    }
  }

  .info {
    display: block;
    padding: 20px 0 14px;
    font-size: 14px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    .left {
      float: left;
    }

    .right {
      float: right;
    }

    span {
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

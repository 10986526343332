@import '../variables';

.item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 300;
    font-size: 16px;
    cursor: pointer;

    &__label {
        color: $black;
        &--scanner {
            font-size: 14px;
        }

        &--letter {
            font-size: 14px;
            font-weight: normal;
            color: $white
        }
    }

    &__icon {
        margin-right: 5px;
        &--menu {
            margin-right: 22px;
        }
    }

    &__button {
        width: 36.4px;
        height: 35.3px;
        border-radius: 5px;
        background-color: $cloudy-blue;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}
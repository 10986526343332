@import "../variables";

.panel {

    &--checkout {
        background-color: $white;
    }

    &__section{
        &--total {
            height: 155px;
            background-color: $white;
            padding: 20px 25px;
        }

        &--numpad {
            height: 558px;
            background-color: $pale-grey-two;
        }
    }


    &--sidebar {
        background-color: $white;
        width: 472px;
        height: 100%;
        padding: 0 22.5px 0 24.5px;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    }

    &__title {
        color: $black;
        padding: 21px 0 ;
        font-size: 18px;
        font-weight: 500;
        border-bottom:  solid 1px $pale-grey-three;
    }

    &__content {
        padding: 25px 0 ;
    }
}
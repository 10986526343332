@import '../variables';

.avatar {
    @extend .text--smaller, .text--medium;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $brand-color;
    color: white;
    text-transform: uppercase;

    &--small {
        width: 30px;
        height: 30px;
        flex: 30 0 auto;
    }

    &--yellow {
        background-color: $category-bg;
        color: white;
        text-transform: uppercase;
    }

}

.text {
    display: inline-block;
    color: $black;

    &--smaller {
        font-size: 12px;
        line-height: 14px;
    }

    &--small {
        font-size: 14px;
        line-height: 17px;
    }

    &--md {
        font-size: 18px;
    }

    &--large {
        font-size: 22px;
        line-height: 29px;
    }

    &--larger {
        font-size: 24px;
        line-height: 29px;
    }

    &--light {
        font-weight: 300;
    }

    &--medium {
        font-weight: 500;
    }

    &--bold {
        font-weight: 700;
    }

    &--danger {
        color: $danger;
    }

    &--gray {
        color: $cool-grey;
    }

    &--brownish-gray {
        color: $brownish-grey;
    }

    &--ellipsis {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        align-items: center;
        min-height: 22px;
    }
}


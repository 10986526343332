.cardstat {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 6px 0 rgba(98, 125, 183, 0.25);
    border-radius: 5px;

    &__container {
        margin-top: 60px;
        margin-bottom: 57px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &--title {
        @extend .text--large, .text--medium;
    }

    &__icon {
        margin-right: 19px;
    }

    &__label {
        @extend .text--small;
        color: #fff;
    }

    &--no-value {
        .cardstat__label {
            @extend .text--larger;
            font-weight: 700;
            color: #fff;
            margin-top: 9px;
        }
    }

    &__value {
        @extend .text--larger;
        color: #fff;
        font-weight: 700;
    }
}

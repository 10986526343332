// Required
@import "node_modules/bootstrap/scss/functions";
@import "./variables";
@import "node_modules/bootstrap/scss/mixins";


@import "node_modules/bootstrap/scss/transitions";


@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/grid";
@import "./forms";
@import "./nav";


//overrides

.modal .modal-header {
    .close {
        box-shadow: none;
        background-color: transparent;
        border: none;
        color: $brand-color;
        font-size: 25px;
        line-height: 22px;
        cursor: pointer;
        outline: none;
    }

    .sr-only {display: none}
}

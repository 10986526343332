@import "../variables";

.tabs {
    margin-top: 15px;

    &__content {
        &--empty {
            padding: 30px;
        }
        &--report {
            background: #fff;
            padding: 0 20px;
        }
    }

    .nav-tabs .nav-link {
        flex: 1;
        font-weight: normal;
        max-width: 250px;

        @extend .text--small;
    }

    .nav-tabs {
        border-bottom: 2px solid $brand-color;
    }
}

@import '../variables';

.user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;

    &__name {
        margin: 0 14px;
        @extend .text--small;
    }

}
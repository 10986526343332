.status {
    display:flex;
    align-items: center;
    justify-content: flex-start;

    &__indicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 10px;

        &--active {
            background-color: $success;
        }

        &--disabled {
            background-color: $danger;
        }
    }

    &__label {
        justify-content: center;
    }
}
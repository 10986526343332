@import "../variables";

.product {

    &__results {
        max-height: 300px;
        overflow: auto;
        margin-bottom: 25px;
    }

    &__row {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
        border-top: 1px solid $table-border;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #d6e3ff;
        }
    }

    &__name {
    }

    &__price {
    }

}

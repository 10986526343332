.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    height: 36px;
    margin-bottom: 10px;

    &__item {
        margin-left: 17px;

        &:first-child {
            margin-left: 0;
        }
    }

    &__left {
        display: flex;
        flex-direction: row;
    }
    &__right {
        display: flex;
        flex-direction: row;
    }
}
.react-datepicker-wrapper {
    width: 100%;
    position: relative;

    &:before {
        display: block;
        content: " ";
        background-image: url("../assets/svgs/calendarInput.svg");
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        position: absolute;
        right: 10px;
        z-index: 1;
        top: 8px;
    }
}

.stat {
    display: flex;
    align-items: center;
    justify-content: center;
    //border-right: 1px solid $cloudy-blue;

    &__container {
        margin-top: 50px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &--title {
       @extend .text--large, .text--medium;
    }

    &--last {
        border-right: none;
    }

    &__icon {
        margin-right: 17px;
    }

    &__info {}

    &__textIntro{
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &__value {
        @extend .text--large, .text--medium;
        color: $brand-color;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &__label {
        @extend .text--small;
        color: $gunmetal;
    }
}
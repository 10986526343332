@import '../variables';

.view {
    &__row {
        padding: 15px 0;
        border-bottom: 1px solid $border;
    }
    &__label {
        @extend .text--light;
        display: inline-block;
        vertical-align: middle;
    }
    &__value {
        @extend .text--medium;
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
    }


}

@import '../variables';
.margin {
  &--rg {
    margin: 15px;
  }

  &__top {
    &--rg {
      margin-top: 15px;
    }
  }

  &__bottom {
    &--rg {
      margin-bottom: 15px;
    }
  }

  &__left {
    &--rg {
      margin-left: 15px;
    }
  }

  &__right {
    &--rg {
      margin-right: 15px;
    }
  }

  &__vertical {
    &--rg {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  &__horizontal {
    &--rg {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &__column {
    margin: 40px 40px 20px 40px;
  }
}

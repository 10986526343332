@import "../variables";
.tooltip {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    cursor: pointer !important;
    padding: 5px 10px;
  }
  &__separator {
    margin: 0 13px;
    width: 1px;
    height: 22px;
    background-color: white;
  }

  &.start {
    padding-left: 0;

    .tooltip__item {
      padding-left: 0;
    }
  }
}

/* Tooltip container */
.tooltipHover {
}

/* Tooltip text */
.tooltipHover .tooltipHovertext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: 40px !important;
  margin-top: -10px !important;
}

.tooltipHover .tooltipHovertext.Cards {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: 260px !important;
  margin-top: -10px !important;
}

.tooltipHover.salereport .tooltipHovertext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: 3px !important;
  margin-top: -35px !important;
}

.tooltipHover.warehouseproducts .tooltipHovertext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: -47px !important;
  margin-top: -35px !important;
}

.tooltipHover.salesreceipts .tooltipHovertext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: -75px !important;
  margin-top: -40px !important;
}

.tooltipHover.addeditproduct .tooltipHovertext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
  margin-left: -47px !important;
  margin-top: -55px !important;
}


/* Show the tooltip text when you mouse over the tooltip container */
.tooltipHover:hover {
  cursor: pointer;
}

.tooltipHover:hover .tooltipHovertext {
  visibility: visible;
  opacity: 1;
}

.tooltipHovertext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.table__heading.table__heading--admin.tooltipHover span {
  margin: auto;
  margin-top: 25px;
}

.table__heading.table__heading--admin.tooltipHover.salereport span {
  margin: auto;
}

.table__heading.table__heading--admin.tooltipHover.warehouseproducts {
  padding: 0;
  margin: auto 30px auto auto;
  width: auto;
}

.table__heading.table__heading--admin.tooltipHover.warehouseproducts span {
  margin: auto;
}


.table__heading.table__heading--admin.tooltipHover.addeditproduct {
  padding: 0;
  margin: auto 30px auto auto;
  width: auto;
}

.table__heading.table__heading--admin.tooltipHover.addeditproduct span {
  margin: auto;
}
